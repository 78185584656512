module services {
    export module reporting {
        export class reportService implements interfaces.reporting.IReportService {
            static $inject = ["$resource", "ENV", "$http", "generalService", "$timeout"];
            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig,
                private $http: ng.IHttpService, public generalService: interfaces.applicationcore.IGeneralService,
                private $timeout: ng.ITimeoutService) {
            }

            getDocumentList(modelType: Enum.EnumModelType, transactionId: number): ng.resource.IResourceClass<interfaces.reporting.IReport> {
                return this.$resource<interfaces.reporting.IReport>(this.ENV.DSP_URL + "Report/GetDocumentList", {
                    modelType: modelType,
                    transactionId: transactionId
                });
            }

            getReportList(modelType: Enum.EnumModelType): ng.resource.IResourceClass<interfaces.reporting.IReport> {
                return this.$resource<interfaces.reporting.IReport>(this.ENV.DSP_URL + "Report/GetReportList", {
                    modelType: modelType
                });
            }

            GetReportParameters(reportId: number): ng.resource.IResourceClass<interfaces.reporting.IReportParameter> {
                return this.$resource<interfaces.reporting.IReportParameter>(this.ENV.DSP_URL + "Report/GetReportParameters", {
                    reportId: reportId
                });
            }

            GetReportDetail(reportId: number): ng.resource.IResourceClass<interfaces.reporting.IReport> {
                return this.$resource<interfaces.reporting.IReport>(this.ENV.DSP_URL + "Report/GetReportDetail", {
                    reportId: reportId
                });
            }

            GetDocumentFromReport(reportId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Report/GetDocumentFromReport", {
                    reportId: reportId
                });
            }

            saveToDocument(selectedIds: Array<number>, referenceId: number, modelType: Enum.EnumModelType, version?: number, forceXLS?: boolean): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);
                
                var url = this.ENV.DSP_URL + 'Report/SaveToDocument?';

                if (selectedIds.length > 1)
                {
                    _.forEach(selectedIds, (id) => {
                        url += 'selectedReportIds=' + id + '&';
                    });
                }
                else
                {
                    if (selectedIds.length == 1)
                    {
                        url += 'selectedReportIds=' + selectedIds[0] + '&';
                    }
                }

                url += 'referenceId=' + referenceId + '&modelType=' + modelType;

                if (version) {
                    url += '&version=' + version;
                }

                if (forceXLS) {
                    url += '&forceXLS=' + forceXLS;
                }

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'download.pdf';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            saveCertificateToDocument(consignmentId: number, previewOnly: boolean, certificateNumber: string, modelType: Enum.EnumModelType, reportId: number, version?: number): ng.IPromise<void> {
                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'Report/SaveCertificateToDocument?';

              

                url += 'consignmentId=' + consignmentId + '&previewOnly=' + previewOnly + '&certificateNumber=' + certificateNumber + '&modelType=' + modelType + '&reportId=' + reportId;

                if (version) {
                    url += '&version=' + version;
                }

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'Certificate.pdf';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            getReportExcel(params: any): ng.IPromise<void> {

                var pdfDownload = document.createElement("a");
                document.body.appendChild(pdfDownload);

                var url = this.ENV.DSP_URL + 'Report/GetReportExcel?';

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                searchObject = params.connectSearch;

                url += 'connectSearch=' + btoa(JSON.stringify(searchObject));

                if (params.reportId)
                    url += "&reportId=" + params.reportId;

                return this.$http({
                    method: 'GET',
                    responseType: 'arraybuffer',
                    url: url,
                }).then((response) => {
                    var filename = response.headers()['x-filename'] || 'download.xlsx';
                    var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });

                    if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                        window.navigator.msSaveBlob(fileBlob, filename);
                    } else { // for other browsers
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        pdfDownload.href = fileURL;
                        pdfDownload.download = filename;
                        pdfDownload.click();
                        pdfDownload.remove();
                    }

                    this.$timeout(() => {
                        this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                    });
                }).catch((errorResponse) => {
                    var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                    var fileURL = window.URL.createObjectURL(fileBlob);
                    const reader = new FileReader();

                    // This fires after the blob has been read/loaded.
                    reader.addEventListener('loadend', (e: ProgressEvent) => {
                        const text = e.target['result'];

                        this.$timeout(() => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                        });
                    });

                    // Start reading the blob as text.
                    reader.readAsText(fileBlob);
                });
            }

            getCertificateNumbers(consignmentId: number, reportId: number): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Report/GetCertificateNumbers", {
                    consignmentId: consignmentId,
                    reportId: reportId
                });
            }
            

            saveToDocumentRepository(selectedReportIds: Array<number>, referenceId: number, modelType: Enum.EnumModelType, version?: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "Report/SaveToDocumentRepository", {
                    selectedReportIds: selectedReportIds,
                    referenceId: referenceId,
                    modelType: modelType,
                    version: version
                });
            }
        }
    }
    angular.module("app").service("reportService", services.reporting.reportService);
}